//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { saveCompanyMember } from "@/api/partner";
import { fetchMemberList } from "@/api/member";
import "@/utils/global.js";
export default {
  name: "dialogFormEdit",
  data: function data() {
    return {
      title: "添加员工",
      dialogVisible: false,
      dialogForm: {
        id: "",
        company_id: "",
        name: "",
        type: "",
        user_id: "",
        status: "normal",
        remark: ""
      },
      rules: {
        name: [{
          required: true,
          message: "请输入成员名称"
        }],
        user_id: [{
          required: true,
          message: "请选择用户"
        }],
        type: [{
          required: true,
          message: "请选择类型"
        }]
      },
      companyType: _global.companyType,
      submitLoading: false,
      //搜索小区
      memberLoading: false,
      memberOptions: []
    };
  },
  methods: {
    showAdd: function showAdd(row) {
      var _this = this;

      this.title = "添加员工";
      this.dialogVisible = true;
      this.dialogForm.company_id = row.company_id;
      this.dialogForm.company_name = row.company_name;
      this.$nextTick(function () {
        _this.$refs["dialogForm"].clearValidate();
      });
    },
    showEdit: function showEdit(dialogForm) {
      var _this2 = this;

      this.title = "编辑员工";
      this.dialogForm = dialogForm;
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dialogForm"].clearValidate();
      });
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;

      if (this.submitLoading == true) {
        return;
      }

      console.log(this.dialogForm);

      if (parseFloat(this.dialogForm.settle_ratio) + parseFloat(this.total_settle_ratio) > 100) {
        this.$message({
          type: "error",
          message: "结算比例超过100%"
        });
        return false;
      }

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          _this3.submitLoading = true;
          var data = Object.assign({}, _this3.dialogForm);
          saveCompanyMember(data).then(function (res) {
            // 成功之后刷新或
            // this.dataList.unshift('返回的数据')
            _this3.$message({
              type: "success",
              message: _this3.title + "成功"
            });

            _this3.dialogVisible = false;
            _this3.submitLoading = false;

            _this3.$emit("fetch-data");
          }).catch(function (err) {
            _this3.submitLoading = false;

            _this3.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
      return false;
    },
    //搜索用户
    remoteMember: function remoteMember(query) {
      var _this4 = this;

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          _this4.memberLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this4.memberOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    },
    close: function close() {
      this.$refs["dialogForm"].resetFields();
      this.dialogVisible = false;
    }
  }
};