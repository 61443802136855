import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/Users/paitony/Works/www/mx/charge_pile/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import CompanyMemberEdit from "./components/CompanyMemberEdit.vue";
import { fetchCompanyMemberList, deleteCompanyMember, updateCompanyMemberStatus } from "../../../api/partner";
export default {
  name: "PartnerCompanyMemberList",
  components: {
    Pagination: Pagination,
    CompanyMemberEdit: CompanyMemberEdit
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        company_id: ''
      },
      companyType: _global.companyType,
      company_id: "",
      company_name: "",
      // 表格数据
      list: [],
      // 总数
      totalPage: 0,
      tableLoading: false
    };
  },
  created: function created() {
    this.company_id = this.$route.query ? this.$route.query.company_id : '';
    this.company_name = this.$route.query ? this.$route.query.company_name : '';
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;

      this.tableLoading = true;
      this.searchForm.company_id = this.company_id;
      fetchCompanyMemberList(this.searchForm).then(function (response) {
        _this.list = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleAdd: function handleAdd() {
      this.$refs.companyMemberEdit.showAdd({
        company_id: this.company_id,
        company_name: this.company_name
      });
    },
    handleEdit: function handleEdit(row) {
      row.company_name = this.company_name;
      this.$refs.companyMemberEdit.showEdit(row);
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.fetchList();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.fetchList();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.fetchList();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleStatus: function handleStatus(row) {
      var _this2 = this;

      var status = 'normal';

      if (row.status == 'normal') {
        status = 'disable';
      } else {
        status = 'normal';
      }

      updateCompanyMemberStatus({
        id: row.id,
        status: status
      }).then(function (res) {
        _this2.$message({
          type: "success",
          message: "修改状态成功"
        });

        row.status = status;
      }).catch(function (err) {
        _this2.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleDelete: function handleDelete(row) {
      var _this3 = this;

      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u5458\u5DE5";
      var title = "删除员工";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var id = row.id;
        deleteCompanyMember(id).then(function (res) {
          _this3.$message({
            type: "success",
            message: "删除员工成功"
          });

          var index = _this3.list.indexOf(row);

          _this3.list.splice(index, 1);
        }).catch(function (err) {
          _this3.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    queryData: function queryData(searchForm) {
      this.searchForm.page = 1;
      this.searchForm = _objectSpread(_objectSpread({}, this.searchForm), searchForm);
      this.fetchList();
    }
  }
};