var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "form-container" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search_form",
              attrs: { model: _vm.searchForm, inline: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "270px" },
                attrs: { clearable: "", placeholder: "会员ID/手机/姓名/昵称" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSearch($event)
                  }
                },
                model: {
                  value: _vm.searchForm.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.searchForm, "keyword", $$v)
                  },
                  expression: "searchForm.keyword"
                }
              }),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "batch-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("添加员工")]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: { data: _vm.list, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  "header-align": "center",
                  align: "center",
                  width: "80px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "成员名称",
                  "header-align": "center",
                  align: "center",
                  width: "150px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "user_id",
                  label: "用户",
                  "header-align": "center",
                  align: "left",
                  width: "180px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticStyle: { "padding-left": "10px" } }, [
                          _c("div", [
                            _vm._v(
                              "昵称：" +
                                _vm._s(
                                  scope.row.user.nickname
                                    ? scope.row.user.nickname
                                    : "-"
                                )
                            )
                          ]),
                          _c("div", [
                            _vm._v(
                              "姓名：" +
                                _vm._s(
                                  scope.row.user.realname
                                    ? scope.row.user.realname
                                    : "-"
                                )
                            )
                          ]),
                          _c("div", [
                            _vm._v("手机：" + _vm._s(scope.row.user.mobile))
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: "成员类型",
                  "header-align": "center",
                  align: "center",
                  width: "150px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.type == "agent"
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("代理")
                            ])
                          : _vm._e(),
                        scope.row.type == "investor"
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("投资人")
                            ])
                          : _vm._e(),
                        scope.row.type == "property"
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("物业")
                            ])
                          : _vm._e(),
                        scope.row.type == "intermediary"
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("居间人员")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    scope.row.status == "normal"
                                      ? "success"
                                      : "info"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status == "normal"
                                      ? "正常"
                                      : "禁用"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_owner",
                  label: "是否为负责人",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    scope.row.is_owner == 1 ? "success" : "info"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.is_owner == 1 ? "是" : "否")
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: "备注",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "created_at",
                  label: "创建时间",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updated_at",
                  label: "更新时间",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  width: "200px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n           编辑\n          ")]
                        ),
                        scope.row.is_owner == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleStatus(scope.row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n           " +
                                    _vm._s(
                                      scope.row.status != "normal"
                                        ? "启用"
                                        : "禁用"
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        scope.row.is_owner == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(scope.row)
                                  }
                                }
                              },
                              [_vm._v("\n           删除\n          ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totalPage > 0,
            expression: "totalPage > 0"
          }
        ],
        attrs: {
          total: _vm.totalPage,
          page: _vm.searchForm.page,
          limit: _vm.searchForm.per_page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchForm, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchForm, "per_page", $event)
          },
          pagination: _vm.fetchList
        }
      }),
      _c("company-member-edit", {
        ref: "companyMemberEdit",
        on: { "fetch-data": _vm.queryData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }