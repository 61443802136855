var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, width: "800px", visible: _vm.dialogVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dialogForm",
          attrs: {
            "label-position": "right",
            "label-width": "130px",
            model: _vm.dialogForm,
            rules: _vm.rules
          }
        },
        [
          _c("el-form-item", { attrs: { label: "公司名称:" } }, [
            _vm._v("\n      " + _vm._s(_vm.dialogForm.company_name) + "\n    ")
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "成员名称:", prop: "name" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "" },
                model: {
                  value: _vm.dialogForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.dialogForm, "name", $$v)
                  },
                  expression: "dialogForm.name"
                }
              })
            ],
            1
          ),
          _vm.dialogForm.id == ""
            ? _c(
                "el-form-item",
                { attrs: { label: "用户:", prop: "user_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入选择搜索",
                        "remote-method": _vm.remoteMember,
                        loading: _vm.memberLoading,
                        clearable: ""
                      },
                      model: {
                        value: _vm.dialogForm.user_id,
                        callback: function($$v) {
                          _vm.$set(_vm.dialogForm, "user_id", $$v)
                        },
                        expression: "dialogForm.user_id"
                      }
                    },
                    _vm._l(_vm.memberOptions, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: {
                            label:
                              item.nickname || item.realname || item.mobile,
                            value: item.id
                          }
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.nickname || item.realname))
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "right",
                                color: "#8492a6",
                                "font-size": "13px"
                              }
                            },
                            [_vm._v(_vm._s(item.mobile))]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _c("el-form-item", { attrs: { label: "用户:" } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.dialogForm.user.mobile) + "\n    "
                )
              ]),
          _c(
            "el-form-item",
            { attrs: { label: "类型:", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择类型" },
                  model: {
                    value: _vm.dialogForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.dialogForm, "type", $$v)
                    },
                    expression: "dialogForm.type"
                  }
                },
                _vm._l(_vm.companyType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态:", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.dialogForm.is_owner == 1 },
                  model: {
                    value: _vm.dialogForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.dialogForm, "status", $$v)
                    },
                    expression: "dialogForm.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "normal" } }, [
                    _vm._v("正常")
                  ]),
                  _c("el-radio", { attrs: { label: "disable" } }, [
                    _vm._v("禁止")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注:" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "500px" },
                attrs: { clearable: "", placeholder: "" },
                model: {
                  value: _vm.dialogForm.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.dialogForm, "remark", $$v)
                  },
                  expression: "dialogForm.remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "footer-container",
          staticStyle: { "padding-top": "10px" }
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }